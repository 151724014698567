<template>
  <div class="datatable-wrapper">
<!--    :class="{ 'datatable&#45;&#45;small-text': isSpoilerTable }"-->
<!--    v-if="((!noPositions || showDeletedPositions) && item?.positions?.length) || item?.consumable">-->
    <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table"
           :class="{ 'datatable&#45;&#45;small-text': isSpoilerTable }"
           v-if="((!noPositions || showDeletedPositions) && item?.positions?.length) || item?.consumable">
      <thead class="p-datatable-tbody"
             :class="{ 'p-datatable-thead' : !isSpoilerTable }">
<!--        <tr class="table-opened-info__header-row">-->
<!--          <th style="width: 30px"></th>-->
<!--          <th style="width: 14%">Supplier</th>-->
<!--          <th style="width: 12%">Code</th>-->
<!--          <th style="width: 24%; min-width: 150px">Name</th>-->
<!--          <th style="width: 6%">Qty</th>-->
<!--          <th style="width: 7%">Purchase</th>-->
<!--          <th style="width: 7%">Sell</th>-->
<!--          <th style="width: 7%">Dis.</th>-->
<!--          <th style="width: 8%">Sum</th>-->
<!--          <th style="width: 7%">Tax</th>-->
<!--          <th style="width: 8%">Total</th>-->
<!--          <th v-if="userIsAdminOrSuperadmin" style="width: 7%">Margin</th>-->
<!--          <th v-if="userIsAdminOrSuperadmin" style="width: 7%">Profit</th>-->
<!--          <th v-show="includesRequestPositions" style="width: 50px"></th>-->
<!--          <th style="width: 52px"></th>-->
<!--          <th v-show="includesRequestPositions && includesFixedQty" style="width: 52px"></th>-->
<!--        </tr>-->
        <tr class="table-opened-info__header-row">
          <th style="max-width: 30px"></th>
          <th :class="{'medium-width': showFinancialData && isSpoilerTable}" v-show="showFinancialData">{{ $t('Supplier') }}</th>
          <th :class="{'medium-width': showFinancialData && isSpoilerTable}">{{ $t('Code') }}</th>
<!--          <th style="width: 20%; min-width: 260px">{{ $t('Name') }}</th>-->
          <th>{{ $t('Name') }}</th>
<!--          <th :class="{'small-width': isSpoilerTable}">Name</th>-->
          <th :class="{'small-width': showFinancialData && isSpoilerTable}">{{ $t('Qty') }}</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Buy</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Sell</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Dis.</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Sum</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Tax</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Total</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData">Margin</th>
          <th :class="{'small-width': isSpoilerTable}" v-show="showFinancialData && userIsAdminOrSuperadmin">Profit</th>
          <th style="width: 3%" v-show="showFinancialData && includesRequestPositions && !isOffer"></th>
          <th style="width: 3%" v-show="showFinancialData"></th>
          <th style="width: 3%" v-if="showFinancialData && includesRequestPositions && includesFixedQty && !isOffer"></th>
        </tr>
      </thead>
      <tbody class="p-datatable-tbody">
<!--        <tr class="table-opened-info__row" v-for="(position, index) of computedActualPositions" :key="index">-->
        <tr v-for="(position, index) of computedActualPositions" :key="index">
  <!--        <template v-if="position.status === 1">-->
          <td style="width: 30px" :class="{'deleted-position-td': position.status === 0}">
            <i :class="{'ti-hummer':position.type === 1,
                        'ti-truck':position.type === 2,
                        'ti-shopping-cart':position.type === 3,
                        'ti-pencil':position.type === 4}">
            </i>
          </td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">
            <span v-if="position.type === 1 && position.master">{{ position.master.first_name }} {{ position.master.last_name }}</span>
            <span v-else-if="position.type === 2 && position.supplier">{{ position.supplier.company_name }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td :class="{'deleted-position-td': position.status === 0}">
            <span v-if="position.type === 1 && position.service?.code">{{ position.service.code }}</span>
            <span v-else-if="position.code">{{ position.code }}</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td :class="{'deleted-position-td': position.status === 0}">{{ position.name }}</td>
          <td :class="{'deleted-position-td': position.status === 0}">{{ position.qty }}</td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">{{ formatCurrency(position.purchase_price, 2, 4) }}</td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">{{ formatCurrency(position.sell_price, 2, 4) }}</td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">
            <span v-if="position.discount && +position.discount">{{ formatDecimal(position.discount) }}%</span>
            <i v-else class="ti-minus"></i>
          </td>
<!--          <td :class="{'deleted-position-td': position.status === 0}">{{ formatCurrency(((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * position.qty)) }}</td>-->
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">{{ formatCurrency(position.sum) }}</td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">{{ formatDecimal(position.tax_value) }}%</td>
<!--          <td :class="{'deleted-position-td': position.status === 0}">{{ formatCurrency(((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * position.qty) * (((position.tax ? position.tax.value : 0) / 100) + 1)) }}</td>-->
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">{{ formatCurrency(position.total) }}</td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">{{ calculatePositionMargin(position) }}</td>
          <td :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData && userIsAdminOrSuperadmin">{{ formatCurrency(calculatePositionProfit(position)) }}</td>
          <td class="data-custom-padding" :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData && includesRequestPositions && !isOffer">
            <RequestPositionStateButton :isDisabled="item.state === 12 && !userIsAdminOrSuperadmin" :orderId="item.id" :orderIsArchived="!!item.is_archived" :position="position" @update-items="updateItems"/>
          </td>
          <td class="data-custom-padding" :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData">
            <PositionHistoryButton :position="position"/>
          </td>
          <!--                            <td><a @click.prevent href="#" class="p-link table-link-icon"><i class="ti-link"></i></a></td>-->
          <td class="data-custom-padding" :class="{'deleted-position-td': position.status === 0}" v-show="showFinancialData && includesRequestPositions && includesFixedQty && !isOffer">
            <PositionFixedQtyButton :position="position"/>
          </td>
        </tr>
        <tr v-if="item.consumable && showFinancialData">
          <td>
            <i class="ti-spray"></i>
          </td>
          <td>
<!--            <i class="ti-minus"></i>-->
          </td>
          <td>
<!--            <i class="ti-minus"></i>-->
          </td>
          <td>{{ item.consumable.name }}</td>
          <td>{{ item.consumable.qty }}</td>
<!--          <td>{{ formatCurrency(item.consumable.purchase_price) }}</td>-->
          <td><i class="ti-minus"></i></td>
          <td>{{ formatCurrency(item.consumable.sell_price) }}</td>
          <td>
            <span v-if="item.consumable.discount && +item.consumable.discount">{{ formatDecimal(item.consumable.discount) }}%</span>
            <i v-else class="ti-minus"></i>
          </td>
          <td>{{ formatCurrency((item.consumable.sell_price - (((item.consumable.discount ?? 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) }}</td>
          <td>{{ formatDecimal(item.consumable.tax_value) }}%</td>
          <td>{{ formatCurrency(((item.consumable.sell_price - (((item.consumable.discount ?? 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) * ((item.consumable.tax.value / 100) + 1)) }}</td>
          <td v-if="userIsAdminOrSuperadmin">
            <i class="ti-minus"></i>
          </td>
          <td v-if="userIsAdminOrSuperadmin">
            <i class="ti-minus"></i>
          </td>
          <td v-show="includesRequestPositions && !isOffer"></td>
          <td></td>
          <td v-show="includesRequestPositions && includesFixedQty && !isOffer"></td>
        </tr>

        <tr class="table-opened-info__row--total" v-if="showFinancialData && item.positions?.length">
          <td class="no-bottom-border"></td>
          <td class="no-bottom-border"></td>
          <td class="no-bottom-border"></td>
          <td class="no-bottom-border p-text-right">
            <b>Grand total:</b>
          </td>
          <td class="no-bottom-border"></td>
<!--          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + position.purchase_price * position.qty, 0) + (item.consumable ? +item.consumable.purchase_price : 0)) }}</td>-->
          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(computePurchaseSum) }}</td>
<!--          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + position.sell_price * position.qty, 0) + (item.consumable ? +item.consumable.sell_price : 0)) }}</td>-->
          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(computeSellPrice) }}</td>
          <td class="no-bottom-border" style="background-color: inherit">
            <span v-if="computeDiscountSum && +computeDiscountSum !== 0">{{ formatCurrency(computeDiscountSum) }}</span>
            <i v-else class="ti-minus"></i>
          </td>
<!--          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + ((position.sell_price - ((position.discount / 100) * position.sell_price)) * position.qty), 0) + (item.consumable ? ((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) : 0) ) }}</td>-->
<!--          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(computeSum) }}</td>-->
          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(item.sum) }}1</td>
          <td class="no-bottom-border" style="background-color: inherit"><span v-if="item.tax">{{ formatCurrency(item.tax) }}</span></td>
<!--          <td class="no-bottom-border" style="background-color: inherit"><span v-if="item.tax">{{ formatCurrency(computeTotalSum) }}</span></td>-->
          <td class="no-bottom-border" style="background-color: inherit"><span v-if="item.tax">{{ formatCurrency(item.grand_total) }}</span></td>
<!--/*          <td class="no-bottom-border" style="background-color: inherit">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + ((position.sell_price - ((position.discount / 100) * position.sell_price)) * position.qty) * ((position.tax.value / 100) + 1), 0) + (item.consumable ? ((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) * (((item.consumable.tax ? item.consumable.tax.value : 0) / 100) + 1) : 0)) }}</td>*/-->
          <td v-if="userIsAdminOrSuperadmin" class="no-bottom-border"><i class="ti-minus"></i></td>
<!--          <td v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin" class="no-bottom-border">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + ((position.sell_price - (((position.discount || 0) / 100) * position.sell_price)) * position.qty) - (position.purchase_price * position.qty), 0) + (item.consumable ? (((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) - (item.consumable.purchase_price * item.consumable.qty)) : 0)) }}</td>-->
          <td v-if="userIsAdminOrSuperadmin" class="no-bottom-border">{{ formatCurrency(computeTotalProfit) }}</td>
          <td v-show="includesRequestPositions && !isOffer" class="no-bottom-border"></td>
          <td class="no-bottom-border"></td>
          <td v-show="includesRequestPositions && includesFixedQty && !isOffer" class="no-bottom-border"></td>
        </tr>
      </tbody>
    </table>
    <div v-else :class="{ 'datatable--small-text': isSpoilerTable }" style="text-align: center; padding: 35px;">
      {{ $t('No data available' )}}
    </div>
  </div>

  <div class="p-mt-3">
    <div class="p-d-flex" v-if="item.staff_comment || item.customer_comment || item.comments_history?.length">
      <!--      <div class="table-opened-info__row" style="padding: 10px; width: 50%;">-->
      <div class="comment customer-comment" style="padding: 10px; width: 50%;">
        <div>
          <b class="p-mb-3">Customer comment:</b>
          <a @click.prevent="toggleCustomerCommentsHistoryOverlay($event, item.id)" v-if="item.comments_history?.filter(comment => comment.type === 1).length" href="#" class="p-link table-link-icon p-ml-1">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ item.comments_history.filter(comment => comment.type === 1).length }}</span>
          </a>
          <span v-if="item.parts_by" class="p-ml-2">Parts by: <span v-if="item.parts_by === 1">Workshop</span><span v-if="item.parts_by === 2">Customer</span></span>
        </div>
        <OverlayPanel :ref="'ch' + item.id">
          <div v-for="(history, index) of (item.comments_history).filter(comment => comment.type === 1)?.sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.comment">{{ history.comment }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== item.comments_history.filter(comment => comment.type === 1)?.length - 1">
          </div>
        </OverlayPanel>
        <div class="p-mt-2">
          <div>{{ item.customer_comment }}</div>
        </div>
      </div>
      <div class="comment staff-comment" style="padding: 10px; width: 50%;">
        <div>
          <b class="p-mb-3">Internal comment:</b>
          <a @click.prevent="toggleStaffCommentsHistoryOverlay($event, item.id)" v-if="item.comments_history?.filter(comment => comment.type === 2).length" href="#" class="p-link table-link-icon p-ml-1">
            <i class="ti-time"></i>
            <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ item.comments_history.filter(comment => comment.type === 2).length }}</span>
          </a>
        </div>
        <OverlayPanel :ref="'sh' + item.id">
          <div v-for="(history, index) of (item.comments_history).filter(comment => comment.type === 2).sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
            <div class="p-mb-2">
              <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
            </div>
            <div style="font-size: 0.92em; max-width: 600px">
              <span v-if="history.comment">{{ history.comment }}</span>
              <i v-else class="ti-minus"></i>
            </div>
            <hr v-if="index !== item.comments_history.filter(comment => comment.type === 2).length - 1">
          </div>
        </OverlayPanel>
        <div class="p-mt-2" :class="{'warning-color':item.is_warning}">
          {{ item.staff_comment }}
        </div>
      </div>
    </div>
    <!--    <div class="table-opened-info__row" v-if="item.recommendation || item.recommendation_history?.length" style="padding: 10px; margin-top: -7px; margin-left: 0">-->
    <div class="comment recommendations" v-if="item.recommendation || item.recommendation_history?.length" style="padding: 10px; margin-top: -7px; margin-left: 0">
      <div>
        <b class="p-mb-3">Recommendations:</b>
        <a @click.prevent="toggleRecommendationHistoryOverlay($event, item.id)" v-if="item.recommendation_history && item.recommendation_history.length" href="#" class="p-link table-link-icon p-ml-1">
          <i class="ti-time"></i>
          <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1">{{ item.recommendation_history.length }}</span>
        </a>
      </div>
      <OverlayPanel :ref="'rh' + item.id">
        <div v-for="(history, index) of [...item.recommendation_history].sort((a, b) => b.created_at - a.created_at)" :key="index" class="p-mb-2 p-mr-3">
          <div class="p-mb-2">
            <span v-if="history.created_at" >{{ formatDate(+history.created_at) }},</span> <span v-if="history.creator && history.creator.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator && history.creator.last_name">{{ history.creator.last_name }}</span>
          </div>
          <div style="font-size: 0.92em; max-width: 600px">
            <span v-if="history.recommendation">{{ history.recommendation }}</span>
            <i v-else class="ti-minus"></i>
          </div>
          <hr v-if="index !== item.recommendation_history.length - 1">
        </div>
      </OverlayPanel>
      <div class="p-mt-2">
        {{ item.recommendation }}
      </div>
    </div>

    <div class="comment recommendations" v-if="item.additional_information" style="padding: 10px; margin-top: -7px; margin-left: 0">
      <div>
        <b class="p-mb-3">Additional information:</b>
      </div>
      <div class="p-mt-2" :class="{'warning-color':item.is_warning}">
        {{ item.additional_information }}
      </div>
    </div>
  </div>
</template>

<script>
import PositionHistoryButton from '@/components/PositionHistoryButton'
import PositionFixedQtyButton from '@/components/PositionFixedQtyButton'
import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'
import RequestPositionStateButton from "@/pages/orders/components/RequestPositionStateButton";

export default {
  emits: [ 'update-items' ],
  mixins: [ formatMixins ],
  components: { PositionHistoryButton, PositionFixedQtyButton, RequestPositionStateButton },
  name: 'PositionsTable',
  props: {
    showFinancialData: {
      type: Boolean,
      default: true,
    },
    showDeletedPositions: {
      type: Boolean,
      default: false
    },
    item: Object,
    isSpoilerTable: {
      type: Boolean,
      default: false
    },
    isOffer: {
      type: Boolean,
      default: false
    }
    // colspan: Number,
  },
  data() {
    return {
      constants
    }
  },
  methods: {
    calculatePositionProfit(position) {
      if (!position) return 0
      return (position.sum ?? 0) - (position.purchase_sum ?? 0)
    },
    updateItems(item) {
      this.$emit('update-items', item)
    },
    calculatePositionMargin(position) {
      if (!position) return
      // If calculate one/single position
      // const margin = (((position.sell_price - (((position.discount ?? 0) / 100) * position.sell_price)) * 100) / (position.purchase_price ?? 0)) - 100

      // If calculate all positions
      const margin = ((position.sum * 100) / position.purchase_sum) - 100
      if (+margin && isFinite(+margin)) {
        return this.formatDecimal(margin) + '%'
      } else {
        return
      }
    },
    toggleRecommendationHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['rh' + id.toString()].toggle(event);
    },
    toggleCustomerCommentsHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['ch' + id.toString()].toggle(event);
    },
    toggleStaffCommentsHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['sh' + id.toString()].toggle(event);
    },
  },
  computed: {
    userIsAdminOrSuperadmin() {
      return !!(this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin)
    },
    computePurchaseSum() {
      if (!this.item || !this.activePosition) return 0
      const positionsPurchasePrice = this.activePosition.reduce((sum, position) => sum + +position.purchase_sum, 0)
      // const consumablePurchasePrice = this.item.consumable ? (+this.item.consumable.purchase_price ?? 0) : 0
      // return positionsPurchasePrice + consumablePurchasePrice
      return positionsPurchasePrice
    },
    computeSellPrice() {
      if (!this.item || !this.activePosition) return 0
      const positionsSellPrice = this.activePosition.reduce((sum, position) => sum + +position.sell_price * position.qty, 0)
      const consumableSellPrice = this.item.consumable ? (+this.item.consumable.sell_price ?? 0) : 0
      return positionsSellPrice + consumableSellPrice
    },
    computeDiscountSum() {
      if (!this.item || !this.activePosition) return 0

      const positionsDiscountSum = this.activePosition.reduce((sum, position) => sum + +position.discount_sum, 0)
      const consumableDiscountSum = this.item.consumable ? (+this.item.consumable.discount_sum ?? 0) : 0
      return positionsDiscountSum + consumableDiscountSum
    },
    computeSum() {
      if (!this.item || !this.activePosition) return 0

      const positionsDiscountSum = this.activePosition.reduce((sum, position) => sum + +position.sum, 0)
      const consumableDiscountSum = this.item.consumable ? (+this.item.consumable.sum ?? 0) : 0
      return positionsDiscountSum + consumableDiscountSum
    },
    computeTotalSum() {
      if (!this.item || !this.activePosition) return 0

      const positionsDiscountSum = this.activePosition.reduce((sum, position) => sum + +position.total, 0)
      const consumableDiscountSum = this.item.consumable ? (+this.item.consumable.total ?? 0) : 0
      return positionsDiscountSum + consumableDiscountSum
    },
    computeTotalProfit() {
      if (!this.item || !this.activePosition) return 0

      const positionsPurchaseSum = this.activePosition.reduce((sum, position) => sum + +position.purchase_sum, 0)
      const positionsSum = this.activePosition.reduce((sum, position) => sum + +position.sum, 0)
      const positionsProfit = positionsSum - positionsPurchaseSum

      // const consumableProfit = this.item.consumable ? ((+this.item.consumable.sum ?? 0) - (+this.item.consumable.purchase_price ?? 0)) : 0

      return positionsProfit

      // .reduce((sum, position) => sum + ((position.sell_price - (((position.discount || 0) / 100) * position.sell_price)) * position.qty) - (position.purchase_price * position.qty), 0) + (item.consumable ? (((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) - (item.consumable.purchase_price * item.consumable.qty)) : 0))
    },
    includesRequestPositions() {
      if (!this.item.positions) return false

      if (this.showDeletedPositions) {
        return !!this.item.positions.find(position => position.type === 2)
      } else {
        return !!this.item.positions.find(position => position.type === 2 && position.status === 1)
      }
    },
    includesFixedQty() {
      let includesPositionsWithFixedQty = false
      let positions = []
      if (this.showDeletedPositions) {
        positions = this.item.positions?.filter(position => position.state)
      } else {
        positions = this.item.positions?.filter(position => position.state && position.status === 1)
      }

      if (positions.length) {
        positions?.forEach(position => {
          if (+position.fixed_qty > 0) {
            includesPositionsWithFixedQty = true
          }
        })
      }
      return includesPositionsWithFixedQty
    },
    activePosition() {
      return this.item.positions?.filter(position => position.status === 1)
    },
    computedActualPositions() {
      // return this.item.positions;
      if (this.showDeletedPositions) {
        // const positions = [...this.item.positions]?.sort((a, b) => a.sorting - b.sorting)
        // const positions = [...this.item.positions]
        return this.item.positions
      } else {
        // const positions = [...this.item.positions]?.filter(position => position.status === 1).sort((a, b) => a.sorting - b.sorting)
        // const positions = [...this.item.positions]?.filter(position => position.status === 1).sort((a, b) => a.sorting - b.sorting)
        return this.item.positions?.filter(position => position.status === 1)
      }
    },
    noPositions() {
      // if (this.item?.positions) {
      //   return !this.item.positions.find(position => position.status === 1)
      // } else {
      //   return false
      // }
      // return !!this.item.positions
      return false
    },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
//  background-color: inherit!important;
//  //padding-bottom: 25px;
//  &:hover {
//    background-color: inherit!important;
//  }
}

.positions-table {
  //margin-bottom: 5px;
  border-collapse: collapse;
  //overflow-y: auto!important;
  width: 100%;
  tr {
    .small-width {
      width: 6%;
    }
    .medium-width {
      width: 10%;
    }
    th {
      padding: 10px 7px;

    }
    td {
      padding: 7px;
      height: 45px;
      //background-color: inherit !important;
    }
  }
}
.data-custom-padding {
  padding: 3px !important;
}

.datatable--small-text {
  font-size: 0.92em;
}

//.table-opened-info__row {
  //background-color: red!important;
//}

.comment {
}

.customer-comment {
  margin: 0 2px 4px 0 !important;
}
.staff-comment {
  margin: 0 0 4px 2px !important;
}
.recommendations {
  margin: 0 0 4px 0 !important;
}

//.table-opened-info__row--total {
//  background-color: green!important;
//  padding-bottom: 20px!important;
//  margin-bottom: 100px!important;
//}

.no-bottom-border {
  border-bottom: none !important;
}
</style>